// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// action text
window.Trix = require("trix")
require("@rails/actiontext")

// stimulusjs
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// External imports
import "bootstrap";

// jQuery
import $ from 'jquery';
window.$ = window.jQuery = require('jquery')

// jQuery_ujs
// For Ajax request
// import {} from 'jquery-ujs';
import Rails from '@rails/ujs';
global.Rails = window.Rails = Rails;

// Popover
import { popover } from '../components/popover';
window.popover = popover;
popover();

// display goal info
import { showRaceFormat, showRaceDistances } from '../lib/goals'
// display goal select with right options
window.showRaceFormat = showRaceFormat;
window.showRaceDistances = showRaceDistances;

// filter swim distances
import { filterSwimTests } from '../lib/filter_swim_tests';
window.filterSwimTests = filterSwimTests;

// Sweet Alert
import { removeElement, removeGoalWithPlanning } from '../plugins/init_sweet_alert';
window.removeElement = removeElement;
window.removeGoalWithPlanning = removeGoalWithPlanning;

// Flatpickr
import { initFlatPickr, trainingFlatPickr, flatpickrFcs } from '../plugins/init_flatpickr';
window.flatpickrFcs = flatpickrFcs;
window.initFlatPickr = initFlatPickr;
window.trainingFlatPickr = trainingFlatPickr;

// Bike Tests
import { updateFtp, updateNp } from '../lib/bike_tests_dashboard';
window.updateFtp = updateFtp;
window.updateNp = updateNp;
import { filterBikeTests } from '../lib/filter_bike_tests';
window.filterBikeTests = filterBikeTests

// Planning
import { switchDisplay } from '../lib/planning';
window.switchDisplay = switchDisplay;

// Full calendar
import { initCalendar } from '../plugins/full_calendar';
window.initCalendar = initCalendar;
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/list/main.min.css'
import timeGridPlugin from '@fullcalendar/timegrid';
import '@fullcalendar/daygrid/main.min.css'
import listPlugin from '@fullcalendar/list';
import '@fullcalendar/timegrid/main.min.css'
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.allLocales = allLocales;
window.interactionPlugin = interactionPlugin;
window.Draggable = Draggable;

// Sortable
import { templateWeek } from '../plugins/sortable';
window.templateWeek = templateWeek;

import { updateTrainingWeekDetails, displayTrainingWeekDetails } from '../lib/update_training_week';
window.updateTrainingWeekDetails = updateTrainingWeekDetails;
window.displayTrainingWeekDetails = displayTrainingWeekDetails;

import moment from 'moment';
window.moment = moment;
moment.locale('fr');

$(document).ready(function(){
  $('.alertFadeOut').delay(3000).fadeOut(800);
});

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});
