import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  static targets = ['input']
  static values = {}

  connect() {
    flatpickr(this.inputTarget, {
      altInput: true,
      disableMobile: "true",
      locale: French,
      onChange: (_selectedDates, dateStr, instance) => {
        instance._input.value = moment(dateStr).format("Do MMM YYYY");
      },
      onReady: function(selectedDates, dateStr, instance) {
        if (dateStr) {
          instance._input.value = moment(dateStr).format("Do MMM YYYY");
        }
      }
    });
  }
}
