export function updateTrainingWeekDetails() {
  setTimeout(() => {
    const prevButton = document.querySelector('button[title="Précédent"]');
    const nextButton = document.querySelector('button[title="Suivant"]');

    [nextButton, prevButton].forEach(button => {
      button.addEventListener('click', _e => showWeekDetails())
    })
  }, 200);
}

const showWeekDetails = () => {
  $.ajax({
    url: '/training_weeks/',
    type: 'GET',
    data: { beginning_of_week: document.querySelector('[data-date]').dataset.date },
    dataType: 'script'
  });
};

export function displayTrainingWeekDetails() {
  const weekDetails = document.getElementById('week-details');
  const viewButtons = [document.querySelector('button[title="Mois"]'), document.querySelector('button[title="Semaine"]')];
  isWeekViewActive() ? show(weekDetails) : hide(weekDetails);

  viewButtons.forEach(button => {
    button.addEventListener('click', _event => {
      isWeekViewActive() ? show(weekDetails) : hide(weekDetails);
    });
  });
}

function isWeekViewActive() {
  return document.querySelectorAll('.fc-daygrid-day').length <= 7;
}

const show = el => {
  el.classList.remove('d-none');
  el.classList.add('d-flex');
};
const hide = el => {
  el.classList.remove('d-flex');
  el.classList.add('d-none');
};
